.responsive-image-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  @media (max-width: 768px) {
    .responsive-image {
      max-width: 100%;
      height: auto;
    }
  }


  .share-promote-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    margin-top: 10rem;
  }
  
  h1 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .business-link {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin-bottom: 30px;
  }
  
  .business-link p {
    font-size: 16px;
    color: #666;
    word-break: break-all;
  }
  
  .copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .copy-button:hover {
    background-color: #0056b3;
  }
  
  .copy-button svg {
    margin-right: 8px;
  }
  
  .share-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .share-buttons button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .share-buttons button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  }
  
  .facebook { background-color: #3b5998; }
  .twitter { background-color: #1da1f2; }
  .linkedin { background-color: #0077b5; }
  .whatsapp { background-color: #25d366; }
  
  .stats {
    display: flex;
    justify-content: space-around;
  }
  
  .stat-box {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    flex: 1;
    margin: 0 10px;
  }
  
  .stat-box h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .stat-box p {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
  }