.header {
  background-color: #FBFBFB;
  border-bottom: 1px solid #EAEAEA;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.business-logo{
  color: #404EED;
  font-weight: 700;
  font-size: 33px;
  margin-right: -1rem;
}

.mosouq-logo-tag {
  font-family: Lato;
  font-size: 27px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #404eed;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  height: 88px;

}

.logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  position: relative;
}
.nav-links .dropdown {
  position: relative;
}

.nav-links .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.nav-links .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.nav-links .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.nav-links .dropdown:hover .dropdown-content {
  display: block;
}

.nav-links .dropdown:hover a {
  background-color: #ddd;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-icon {
  cursor: pointer;
}

.nav-links a:hover {
  color: #404eed;
}

.business-button-header {
  width: 191px;
  height: 53px;
  padding: 17px 50px;
  border-radius: 33px;
  background: #404eed;
  font-family: "Figtree", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.2px;
  color: #ffffff;
  border: none;
}

.business-button-header-demo {
  width: 200px;
  height: 53px;
  padding: 17px 50px;
  border-radius: 33px;
  background: #404eed;
  font-family: "Figtree", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.2px;
  color: #ffffff;
  border: none;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .header-container {
    padding: 0 1rem;
  }

  .nav-links {
    margin-left: auto;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 88px;
    right: 0;
    background: #fbfbfb;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 0;
    padding: 10px 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    padding: 10px 0;
  }

  .business-button-header {
    width: 100%;
    margin-top: 10px;
  }

  .menu-icon {
    display: block;
  }
}

@media (max-width: 480px) {
  .header-container {
    padding: 0 0.5rem;
  }

  .nav-links a {
    font-size: 14px;
  }

  .business-button-header {
    font-size: 12px;
  }
}
