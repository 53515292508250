.sidebar {
  min-height: 100vh;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  background-color: #404eed !important; /* Blue background for the main sidebar */
  color: white;
  font-weight: 700;
  font-size: 25px;
}

/* .sidebar-top-sec {
  padding: 1rem;
  font-weight: 700;
  font-size: 25px;
  margin-top: -2rem;
} */

.business-Profile-name {
  color: white;
  text-align: center;
  margin-top: 3rem;
}

.business-border {
  margin-top: 1rem;
  border-bottom: 1px solid white;
}

.sidebar .menu-item p {
  color: white;
  font-weight: 700;
  font-size: 25px;
}

.sidebar .menu-item .btn {
  color: white;
}

.sidebar .sub-menu-item p {
  color: white;
  padding-left: 4rem;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 25px;
}

.user-menubar .menu-item {
  display: flex;
  text-align: center;
  padding-top: 25px;
  height: 60px;
  color: #303341;
  width: 100%;
  padding-left: 1.5rem;
}

.user-menubar .menu-item svg {
  font-size: 24px;
}

.user-menubar .menu-item p {
  font-size: 30px;
  margin-left: 25px;
}

.user-menubar .menu-item.active {
  /* background-color: #303341; */
  color: #fff !important;
  width: 100%;
}

div {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    margin: 5px;
    background-color: #999;
    -webkit-border-radius: 5px;
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    height: 5px;
    display: block;
  }
}

.upgrade-button {
  background-color: #404eed;
  color: white;
  border-radius: 50px;
  padding: 10px;
  text-align: center;
}

.plan {
  color: white;
}
