.footer {
    background-color: #262626;
    color: #FFFFFFB2 !important;
    padding: 40px 0;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;

}



.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    flex-wrap: wrap;
    color: #FFFFFFB2;
    margin-left: 4rem;
}

.footer-left {
    margin-top: 3.5rem;
    width: 400px;
}

.footer-left h2 {
    font-size: 24px;
}

.footer-left p {
    font-size: 14px;
    line-height: 1.6;
}

.footer-social-icons a {
    color: #FFFFFFB2;
    margin-right: 25px;
    font-size: 18px;
}

.footer-right {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
}

.footer-column {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    gap: 0;
}

.footer-column h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
    margin-bottom: 2rem;
}

.footer-column ul {
    list-style: none;
    padding: 0;
    color: "red";
}

.footer-column ul li {
    margin-bottom: 10px;
}

.footer-column ul li a {
    color: #DCDCDCCC;
    text-decoration: none;
    font-size: 14px;
}

.footer-bottom {
    border-top: 1px solid #444;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

.footer-bottom ul {
    list-style: none;
    margin-bottom: 10px;
    color: #FFFFFFB2;
    font-size: 18px;
    margin-left: 5rem;
}

.footer-bottom ul li {
    display: inline;
    margin-right: 20px;
    color: #FFFFFFB2;
    margin: 0 2rem;
}

@media (max-width: 768px) {

    .footer-content {
        margin-left: 1rem;
    }

    .footer-bottom ul {
        margin-left: 0;
        text-align: left;
    }

    .footer-bottom ul li {
        display: block;
        margin: 10px 0; /* Adjust margin as needed */
        text-align: left;
        margin-top: 2rem;

    }
}

.footer-bottom ul li a {
    color: #FFFFFFB2;
    text-decoration: none;
    font-size: 16px;
}

.footer-bottom p {
    font-size: 16px;
    color: #FFFFFFB2;
}
