.business-tag-line{
    font-size: 30px;
    font-weight: 500;
    margin-top: 1rem;
    text-align: center;
}

.mosouq-name{
    color: #404EED;
    font-weight: 600;
}

.border{
    border: 1px solid white;
    margin-top: 2rem;
}

.text-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
}
